<template>
  <div class="bg-white" >
    <BaseNav></BaseNav>
    

    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="$store.state.loading"
    ></BaseLoading>
    <div v-else class="container bg-white my-4">
      <div class="row">
        <div class="col-12 col-sm-8 text-left">
          <div class="border-grey--custom bg-white p-3 p-sm-4">
            <div class="">
              <!-- <h1 class="font-weight-bold">PLACE AN ORDER</h1>
              <p>Its fast, secure, and confidential</p>
              <h4>Paper Details</h4> -->

              <form  class="orderForm" @submit.prevent="doPlaceOrder">
                <div class=" row">
                  <label
                    for="inputEmail3"
                    class="col-sm-12 col-form-label py-0 text-left"
                    >Academic Level</label
                  >
                  <div class="col-sm-12 text-left">
                    <div class="btn-group" v-if="academic_levels.length > 0">
                      <button
                        type="button"
                        class="btn w-150 border-primary height-65 p-2 rounded-0"
                        v-for="(level, index) in academic_levels"
                        :key="index + 'al'"
                        :class="
                          orderForm.academic_level === level.id
                            ? 'btn-primary text-white'
                            : 'btn-outline-primary'
                        "
                        @click="activateAcademicLevel(level)"
                      >
                        {{ level.desc }}
                      </button>
                    </div>
                  </div>
                </div>
                  <div class="row">
                  <div class="pl-0  col-sm-6 col-12 ">
                    <label
                      for="inputEmail3"
                      class="col-sm-12 col-form-label py-0 text-left"
                      >Type of Document</label
                    >
                    <div class="col-sm-12">
                      <vue-select
                        label="desc"
                        v-model="orderForm.document_type"
                        placeholder="Start typing to search"
                        @input="calculatePrice"
                        :options="document_types"
                        :reduce="(doc) => doc.id"
                      ></vue-select>
                    </div>
                  </div>
                  <div class=" pr-0 col-sm-6 col-12 ">
                    <label
                      for="inputEmail3"
                      class="col-sm-12 col-form-label py-0 text-left"
                      >Subject Area</label
                    >
                    <div class="col-sm-12">
                      <vue-select
                        label="desc"
                        v-model="orderForm.subject_area"
                        placeholder="Start typing to search"
                        @input="calculatePrice"
                        :options="subject_areas"
                        :reduce="(area) => area.id"
                      ></vue-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6 col-12 ">
                    <div class="form-group row my-1 ">
                      <label
                        for="inputEmail3"
                        class="col-sm-12 col-form-label py-0 text-left"
                        >Title</label
                      >
                      <div class="col-sm-12">
                        <input
                          type="text"
                          v-model="orderForm.title"
                          class="form-control height-60 "
                          id="inputEmail3"
                        />
                      </div>
                    </div>
                    <div class="form-group row my-1">
                      <label
                        for="inputEmail3"
                        class="col-sm-12 py-0 col-form-label text-left"
                        >State</label
                      >
                      <div class="col-sm-12">
                        <input
                          type="text"
                          v-model="orderForm.state"
                          class="form-control height-60"
                          id="inputEmail3"
                        />
                      </div>
                    </div>
                    <div class="form-group row my-1">
                      <label
                        for="inputEmail3"
                        class="col-sm-12 py-0 col-form-label text-left"
                        >Nuring Speciality</label
                      >
                      <div class="col-sm-12">
                        <input
                          type="text"
                          v-model="orderForm.speciality"
                          class="form-control height-60"
                          id="inputEmail3"
                        />
                      </div>
                    </div>

                  </div>
                  <div class="col-sm-6 col-12 ">
                    <div class="form-group row my-1 ">
                      <label
                        for="inputEmail3"
                        class="col-sm-12 py-0 col-form-label text-left"
                        >Description</label
                      >
                      <div class="col-sm-12">
                        <textarea
                          rows="4"
                          v-model="orderForm.description"
                          class="form-control height-300 "
                          id="inputEmail3"
                          placeholder="Description"
                        ></textarea>

                        <!-- <div class="alert rounded-0   alert-warning mt-2">
                          Your personal information (Phone Number, Email Address) is
                          always kept private and we won't share it–just don't
                          include it in your order details.
                        </div> -->
                      </div>
                    </div>
                  </div>

                </div>

                <div class="row">
                  <div class="col-sm-6 col-12 ">
                    <div class="form-group row">
                      <label
                        for="inputEmail3"
                        class="col-sm-12 py-0 col-form-label text-left"
                        >Order Files</label
                      >
                      <div class="col-sm-12">
                        <input
                          type="file"
                          @change="uploadFiles"
                          class="form-control height-60"
                          id="inputEmail3"
                          placeholder="Choose files to upload"
                          multiple="multiple"
                        />
                        <p>Select multiple files</p>
                        <div
                          class="mt-3 p-3 bg-teal-300 text-white rounded"
                          v-if="orderForm.files.length > 0"
                        >
                          Selected files:
                          <br />
                          <small
                            v-for="(file, index) in orderForm.files"
                            :key="index"
                          >
                            {{ file.name }}
                            <br />
                          </small>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="col-sm-6 col-12 ">
                    <div class="form-group row">
                      <label
                        for="inputEmail3"
                        class="col-sm-12 col-form-label text-left py-0"
                        >Paper format/Style</label
                      >
                      <div class="col-sm-12">
                        <vue-select
                          label="desc"
                          v-model="orderForm.style"
                          placeholder="Start typing to search"
                          :options="styles"
                          :reduce="(style) => style.id"
                        ></vue-select>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="row">
                  <div class="col-sm-6 col-12 ">
                    <div class="form-group row  ">
                      <label
                        for="inputEmail3"
                        class="col-sm-12 py-0 col-form-label text-left"
                        >Language Style</label
                      >
                      <div class="col-sm-12">
                        <div class="btn-group" v-if="language_styles.length > 0">
                          <button
                            type="button"
                            class="btn height-60 w-160 border-primary p-2 rounded-0"
                            v-for="(style, index) in language_styles"
                            :key="index"
                            :class="
                              orderForm.language_style === style.id
                              ? 'btn-primary text-white'
                                : 'btn-outline-primary'
                            "
                            @click="activateLanguageStyle(style)"
                          >
                            {{ style.desc }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-12 ">
                    <div class="form-group row">
                      <label
                        for="inputEmail3"
                        class="col-sm-12 py-0 col-form-label text-left"
                        >Deadline / Urgency</label
                      >
                      <div class="col-sm-12">
                        <vue-select
                          label="desc"
                          v-model="orderForm.urgency"
                          placeholder="Start typing to search"
                          @input="calculatePrice"
                          :options="urgency"
                          :reduce="(urg) => urg.id"
                        ></vue-select>
                      </div>
                    </div>
                    
                  </div>

                </div>

                <div class="row">
                  <div class="col-sm-4 col-12 ">
                    <div class="form-group my-1 row">
                      <label class="col-form-label py-0 col-sm-12 col-12 text-left"
                        >Pages</label
                      >
                      <div class="col-12">
                        <div class="input-group">
                          <span class="input-group-prepend">
                            <button
                              class="btn btn-primary height-60 rounded-0 text-white w-80  btn-icon"
                              type="button"
                              @click="updatePages(0)"
                            >
                              <i class="icon-minus3"></i>
                            </button>
                          </span>
                          <input
                            type="number"
                            @change="calculatePrice"
                            v-model="orderForm.pages"
                            name="pages"
                            min="0"
                            :max="number_of_pages.max_pages"
                            class="form-control rounded-0 text-center height-60"
                            placeholder
                          />
                          <span class="input-group-append">
                            <button
                              class="btn btn-primary height-60 rounded-0 text-white w-80  btn-icon"
                              type="button"
                              @click="updatePages(1)"
                            >
                              <i class="icon-plus3"></i>
                            </button>
                          </span>
                        </div>
                        <strong class="mt-1"
                          >Aprox {{ no_of_words || "275" }} words</strong
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4 col-12 ">
                    <div class="form-group my-1 row">
                      <label
                        for="inputEmail3"
                        class="col-sm-12 col-12 py-0 col-form-label text-left"
                        >Spacing</label
                      >
                      <div class="col-sm-12 col-12">
                        <div class="btn-group" v-if="spacing.length > 0">
                          <button
                            type="button"
                            class="btn w-120 border-primary height-60 p-2 rounded-0"
                            v-for="(space, index) in spacing"
                            :key="index + 'sp'"
                            :class="
                              orderForm.spacing === space.id
                              ? 'btn-primary text-white'
                            : 'btn-outline-primary'
                            "
                            @click="activateSpacing(space)"
                          >
                            {{ space.desc }}
                          </button>
                        </div>
                      </div>
                    </div>
                    
                    
                  </div>
                  <div class="col-sm-4 col-12 ">
                    <div class="form-group my-1 row">
                      <label
                        for="inputEmail3"
                        class="col-sm-12 col-12 py-0 col-form-label text-left"
                        >Sources to be cited</label
                      >
                      <div class="col-md-12 col-12">
                        <div class="input-group">
                          <span class="input-group-prepend">
                            <button
                            class="btn  text-white height-60 btn-primary border-primary  rounded-0"
                              type="button"
                              @click="decrementSources"
                            >
                              <i class="icon-minus3"></i>
                            </button>
                          </span>
                          <input
                            type="number"
                            v-model="orderForm.no_sources"
                            name="no_sources"
                            min="0"
                            class="form-control border-primary height-60 rounded-0 text-center"
                            placeholder
                          />
                          <span class="input-group-append">
                            <button
                            class="btn height-60 text-white btn-primary border-primary  rounded-0"
                              type="button"
                              @click="orderForm.no_sources++"
                            >
                              <i class="icon-plus3"></i>
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                    
                    
                  </div>

                </div>

                <div class="row">
                  <div class="col-sm-4 col-12 ">
                    <div class="form-group my-1 row">
                      <label class="col-form-label col-sm-12 col-12 py-0 text-left"
                        >Charts</label
                      >
                      <div class="col-md-12 col-12">
                        <div class="input-group">
                          <span class="input-group-prepend">
                            <button
                            class="btn  text-white btn-primary border-primary height-60  rounded-0"
                              type="button"
                              @click="updateCharts(0)"
                            >
                              <i class="icon-minus3"></i>
                            </button>
                          </span>
                          <input
                            @change="calculatePrice"
                            type="number"
                            v-model="orderForm.charts"
                            name="charts"
                            min="1"
                            class="form-control border-primary height-60 rounded-0 text-center"
                            placeholder
                          />
                          <span class="input-group-append">
                            <button
                            class="btn  text-white btn-primary border-primary height-60  rounded-0"
                              type="button"
                              @click="updateCharts(1)"
                            >
                              <i class="icon-plus3"></i>
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4 col-12 ">
                    <div class="form-group my-1 row">
                    <label class="col-form-label py-0 col-sm-12 col-12 text-left"
                      >PowerPoint slides</label
                    >
                    <div class="col-md-12 col-12">
                      <div class="input-group">
                        <span class="input-group-prepend">
                          <button
                          class="btn  text-white btn-primary border-primary height-60  rounded-0"
                            type="button"
                            @click="updateSlides(0)"
                          >
                            <i class="icon-minus3"></i>
                          </button>
                        </span>
                        <input
                          @change="calculatePrice"
                          type="number"
                          v-model="orderForm.slides"
                          name="slides"
                          min="1"
                          class="form-control border-primary height-60 rounded-0 text-center"
                          placeholder
                        />
                        <span class="input-group-append">
                          <button
                          class="btn  text-white btn-primary border-primary height-60  rounded-0"
                            type="button"
                            @click="updateSlides(1)"
                          >
                            <i class="icon-plus3"></i>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                    
                    
                  </div>
                  

                </div>

                <div class="row">
                  <div class="col-12">
                    <div class="form-group ">
                      <label
                        for="inputEmail3"
                        class="col-sm-12 py-1 col-form-label text-left"
                        >Writer preferences</label
                      >
                      <div class="col-sm-12">
                        <div class="btn-group row" v-if="writer_levels.length > 0">
                          <button
                            type="button"
                            class="btn rounded-0 border-primary col-4"
                            v-for="(pref, index) in writer_levels"
                            :key="index + 'wp'"
                            :class="
                              orderForm.writer_level === pref.id
                                ? 'btn-primary text-white'
                                : 'btn-outline-primary text-muted'
                            "
                            @click="activateWriterPref(pref)"
                          >
                            <div>
                              <h6 class="my-0  text-left">
                                {{ pref.title }}
                                <br />
                                <span>{{ `(${pref.cost})` || "" }}</span>
                              </h6>
                              <p
                                class="my-0 text-left  col-12"
                                style="white-space: normal; word-wrap: break-word"
                              >
                                {{ pref.desc }}
                              </p>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div v-if="isAuthenticated && Object.keys(authUser).length > 0" class="col-sm-12 col-12 "> 
                  <div
                    class="form-group  row"
                    
                  >
                    <label
                      for="inputEmail3"
                      class="col-sm-12 py-0 col-form-label text-left"
                      >Preferred Writer</label
                    >
                    <div class="col-sm-12">
                      <vue-select
                        label="id"
                        v-model="orderForm.writer_id"
                        placeholder="Start typing to search"
                        :options="writers"
                        :reduce="(writer) => writer.id"
                      ></vue-select>
                    </div>
                  </div>
                  </div>
                  <div class="col-sm-11   col-12 " v-else>
                    <div class="form-group row">
                      <label
                        for="inputEmail3"
                        class="col-sm-12 col-form-label text-left"
                        >Writer Id</label
                      >
                      <div class="col-sm-12">
                        <input
                          type="numeric"
                          v-model="orderForm.writer_id"
                          class="form-control"
                          id="inputEmail3"
                        />
                        <div class="alert alert-success my-1">
                          Enter your favourite writer ID if you've had previous
                          writers.
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div
                  class="form-group row"
                  v-if="additional_services.length > 0"
                >
                  <label
                    for="inputEmail3"
                    class="col-sm-12 pt-2 col-form-label text-left"
                    >Additional services</label
                  >
                  <div class="col-sm-12">
                    <div
                      v-for="(service, index) in additional_services"
                      :key="index"
                      class="p-2 is-well mb-1"
                    >
                      <div class="custom-control custom-checkbox">
                        <input
                          v-model="orderForm.additional_services"
                          @change="calculatePrice"
                          type="checkbox"
                          class="custom-control-input border-1"
                          :id="service.id"
                          :value="service.id"
                        />
                        <label class="custom-control-label" :for="service.id">
                          <strong class="my-0">
                            {{ service.title }}
                            <span class="ml-4">+ ${{ service.cost || 0 }}</span>
                          </strong>
                          <p class="my-1">{{ service.desc }}</p>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                
              </form>

              <h1 class="font-weight-bold mt-3">ACCOUNT</h1>
              <div v-if="isAuthenticated && Object.keys(authUser).length > 0">
                <div class="card is-card mb-4">
                  <div class="card-body py-1">
                    <h5 class="my-0">
                      You are logged in as {{ authUser.email || "" }}
                    </h5>
                  </div>

                  <div class="px-3 py-2">
                    <div
                      class="rounded mb-1 py-1 bg-danger-300"
                      v-if="pages_message"
                    >
                      {{ pages_message || "" }}
                    </div>

                    <button
                      type="button"
                      @click="doPlaceOrder('checkout')"
                      class="btn btn-block btn--custom"
                    >
                      Place Order
                    </button>
                  </div>
                </div>
              </div>
              <div v-else class="is-account">
                <ul class="nav nav-tabs nav-tabs-bottom border-bottom-0">
                  <li class="nav-item">
                    <a
                      href="#bottom-divided-tab1"
                      class="nav-link active show"
                      data-toggle="tab"
                      @click="customer_type = 'new'"
                      >New Customer</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      href="#bottom-divided-tab2"
                      class="nav-link"
                      data-toggle="tab"
                      @click="customer_type = 'returning'"
                      >Returning Customer</a
                    >
                  </li>
                </ul>

                <div class="tab-content">
                  <!-- register tab -->
                  <div
                    class="tab-pane fade active show"
                    id="bottom-divided-tab1"
                  >
                    <form
                      class="flex-fill"
                      ref="registerForm"
                      @submit.prevent="doPlaceOrder('register')"
                    >
                      <div class="form-group row">
                        <label
                          for="inputEmail3"
                          class="col-sm-3 col-form-label text-right"
                          >First & Last Names</label
                        >
                        <div class="col-sm-9">
                          <div class="row">
                            <div class="col-md-6">
                              <div
                                class="
                                  form-group
                                  form-group-feedback
                                  form-group-feedback-right
                                "
                              >
                                <input
                                  v-model="registerForm.first_name"
                                  name="first_name"
                                  type="text"
                                  class="form-control"
                                  placeholder="First name"
                                />
                                <div class="form-control-feedback">
                                  <i class="icon-user-check text-muted"></i>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div
                                class="
                                  form-group
                                  form-group-feedback
                                  form-group-feedback-right
                                "
                              >
                                <input
                                  v-model="registerForm.last_name"
                                  name="last_name"
                                  type="text"
                                  class="form-control"
                                  placeholder="Second name"
                                />
                                <div class="form-control-feedback">
                                  <i class="icon-user-check text-muted"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label
                          for="inputEmail3"
                          class="col-sm-3 col-form-label text-right"
                          >Email</label
                        >
                        <div class="col-sm-9">
                          <input
                            v-model="registerForm.email"
                            name="email"
                            type="email"
                            class="form-control"
                            placeholder="Email Address"
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label
                          for="inputEmail3"
                          class="col-sm-3 col-form-label text-right"
                          >Phone</label
                        >
                        <div class="col-sm-9">
                          <input
                            v-model="registerForm.phone"
                            name="phone"
                            type="tel"
                            class="form-control"
                            placeholder="Your Phone"
                          />
                        </div>
                      </div>

                      <div class="form-group row">
                        <label
                          for="inputEmail3"
                          class="col-sm-3 col-form-label text-right"
                          >Country</label
                        >
                        <div class="col-sm-9">
                          <!-- <select
                            v-model="registerForm.country"
                            name="country"
                            type="select"
                            class="form-control"
                            placeholder="Country"
                          >
                            <option value="Kenya">Kenya</option>
                            <option value="Uk">United Kingdom</option>
                          </select>-->
                          <vue-select
                            label="name"
                            v-model="registerForm.country"
                            placeholder="Start typing to search"
                            :options="countryNames"
                            :reduce="(country) => country"
                          >
                          </vue-select>
                        </div>
                      </div>

                      <div class="form-group row">
                        <label
                          for="inputEmail3"
                          class="col-sm-3 col-form-label text-right"
                          >Password</label
                        >
                        <div class="col-sm-9">
                          <div class="row">
                            <div class="col-md-6">
                              <div
                                class="
                                  form-group
                                  form-group-feedback
                                  form-group-feedback-right
                                "
                              >
                                <input
                                  v-model="registerForm.password"
                                  name="password"
                                  type="password"
                                  class="form-control"
                                  placeholder="Create password"
                                />
                                <div class="form-control-feedback">
                                  <i class="icon-user-lock text-muted"></i>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div
                                class="
                                  form-group
                                  form-group-feedback
                                  form-group-feedback-right
                                "
                              >
                                <input
                                  v-model="registerForm.password_confirmation"
                                  name="password_confirmation"
                                  type="password"
                                  class="form-control"
                                  placeholder="Repeat password"
                                />
                                <div class="form-control-feedback">
                                  <i class="icon-user-lock text-muted"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col-sm-9">
                          <button type="submit" class="btn btn-primary">
                            Create an Account
                          </button>
                          <!-- <button class="btn btn-link">Forgot Password</button> -->
                        </div>
                      </div>
                    </form>
                  </div>
                  <!-- /register tab -->

                  <!-- login tab -->
                  <div class="tab-pane fade" id="bottom-divided-tab2">
                    <form
                      class="is-login"
                      ref="loginForm"
                      @submit.prevent="doPlaceOrder('login')"
                    >
                      <div class="form-group row">
                        <label
                          for="inputEmail3"
                          class="col-sm-3 col-form-label text-right"
                          >Email</label
                        >
                        <div class="col-sm-9">
                          <input
                            type="email"
                            v-model="loginForm.email"
                            class="form-control"
                            id="inputEmail3"
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label
                          for="inputEmail3"
                          class="col-sm-3 col-form-label text-right"
                          >Password</label
                        >
                        <div class="col-sm-9">
                          <input
                            type="password"
                            v-model="loginForm.password"
                            class="form-control"
                            id="inputEmail3"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col-sm-9">
                          <button class="btn btn-primary" type="submit">
                            Sign in
                          </button>
                          <!-- <button class="btn btn-link">Forgot Password</button> -->
                        </div>
                      </div>
                    </form>
                  </div>
                  <!-- /login tab -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-4 text-right">
          <div class="total-summary-fixed">
            <div class="card px-0 pb-4   totals-summary">
              <div class="card-body">
                <h4 class="summary__title text--dark--custom  text-left border-bottom   ">Summary</h4>

                <h6 class="order-topic text-left">
                  <strong>Title: </strong> {{ orderForm.title }}
                </h6>
                <table
                  class="borderless"
                  style="min-width: 100%; text-align: left !important"
                >
                  <tr>
                    <td class="order-short-info mb-0 pb-1">
                      <strong>Academic level:</strong> {{ academic_level }}
                    </td>
                  </tr>
                  <tr>
                    <td class="order-short-info mb-0 pb-1">
                      <strong>Subject area: </strong>{{ subject_area }}
                    </td>
                  </tr>
                  <tr>
                    <td class="order-short-info mb-0 pb-1">
                      <strong>Type of paper:</strong> {{ document_type }}
                    </td>
                  </tr>
                </table>
                <hr class="mt-0" />
                <table
                  class="borderless"
                  style="min-width: 100%; text-align: left !important"
                >
                  <tr
                    v-for="(price, index) in getPrice"
                    :key="index + 'test'"
                    :class="price.title == 'Total' ? 'total-label' : ''"
                  >
                    <td>{{ price.title || "" }}</td>
                    <td class="text-right text-black font-weight-bold price">
                      ${{ price.value || "" }}
                    </td>
                  </tr>

                  <!-- <tr>
                    <td colspan="2">
                      <div
                        v-if="message"
                        class="alert my-1 rounded"
                        :class="message.type ? 'alert-success' : 'alert-danger'"
                      >
                        <span v-html="message.message || ''"></span>
                      </div>
                    </td>
                  </tr> -->
                </table>
              </div>
              <div class="card-footer px-3 pb-2">
                <div class="link-discount__block">
                      <div  class="d-flex py-2 align-items-center justify-content-between">
                          <div class="link-discount-price" 
                          style="
                          display: flex !important; 
                          flex-direction: row;
                          align-items: start;
                          "><span type="button" @click="show_coupon = !show_coupon" class="text-decoration">Enter a promo code</span> <span class="discount-optional">(optional)</span></div>
                          <div v-if="message"  style="display: block;"  :class="message.type ? 'd-flex border-success text-success ' : 'd-flex border-danger text-danger'" class="link-discount-price-approved border "><span class="">Promo code {{message.type ? 'applied' : 'invalid' }} </span></div>
                      </div>
                  </div>
                  <div v-show="show_coupon" class="form-group mb-2 text-left">
                    <!-- <label for="inputEmail3" class="text-left">Coupon Code</label> -->
                    <div>
                      <input
                        type="text"
                        placeholder="Enter coupon code for discounts"
                        v-model="orderForm.coupon_code"
                        class="form-control"
                        id="inputEmail3"
                        @input="calculatePrice"
                      />
                      <!-- <div
                        v-if="message"
                        class="alert my-1 rounded"
                        :class="message.type ? 'alert-success' : 'alert-danger'"
                      >
                        <span v-html="message.message || ''"></span>
                      </div> -->
                      <div v-if="coupon" class="alert alert-success rounded my-1">
                        <span v-html="coupon || ''"></span>
                      </div>
                    </div>
                  </div>
                <div
                  class="rounded mb-1 py-1 bg-danger-300"
                  v-if="pages_message"
                >
                  {{ pages_message || "" }}
                </div>

               
              </div>
              <!-- <div class="text-center">
                <img :src="mcafee" width="100px" alt="mcafee secure" />
              </div> -->
             
            </div>
            <button
            v-if="step == 1 || step == 2"
            type="button"
            @click="nextStep(step + 1)"
            class="btn btn-block btn--custom  "
            style="width: 250px;"
          >
            Next Step
          </button>
          <button
            v-else
            type="button"
            @click="doPlaceOrder('checkout')"
            class="
              btn btn-block btn--custom
              text-uppercase
            "
            style="width: 300px;"
            :disabled="is_loading"
          >
            {{ is_loading ? "Loading.." : "Proceed to safe checkout" }}
          </button>
          </div>
          </div>
      </div>
    </div>

    <BaseOverlay v-if="is_loading"></BaseOverlay>
  </div>
</template>

<script>
import mcafee from "../../assets/global_assets/images/mcafee.png";
import axios from "axios";
import { countries } from "countries-list";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: "PlaceOrder",
  computed: {
    countryNames() {
      return Object.keys(countries).map((country) => countries[country].name);
    },
    ...mapGetters("setups", [
      "academic_levels",
      "document_types",
      "language_styles",
      "number_of_pages",
      "spacing",
      "styles",
      "subject_areas",
      "urgency",
      "writer_levels",
      "additional_services",
      "defaults",
    ]),
    ...mapGetters("auth", ["isAuthenticated", "authUser"]),
    ...mapGetters("order", ["getPrice"]),
    ...mapState("order", ["message", "coupon"]),
    ...mapState("writers", ["writers"]),
    ...mapState("setups", ["countries"]),
    no_of_words() {
      return (
        this.number_of_pages.words_per_page *
          this.orderForm.pages *
          this.spacing.find((x) => x.id == this.orderForm.spacing).factor || ""
      );
    },

    subject_area() {
      return (
        this.subject_areas.find((x) => x.id == this.orderForm.subject_area)
          .desc || ""
      );
    },
    document_type() {
      return (
        this.document_types.find((x) => x.id == this.orderForm.document_type)
          .desc || ""
      );
    },
    academic_level() {
      return (
        this.academic_levels.find((x) => x.id == this.orderForm.academic_level)
          .desc || ""
      );
    },

    pages_message() {
      let message = "";
      if (
        this.orderForm.pages < 1 &&
        this.orderForm.charts < 1 &&
        this.orderForm.slides < 1
      ) {
        message = "You need to order at least 1 page, 1 slide or 1 chart";
      } else message = "";
      return message;
    },
  },
  data() {
    return {
      show_coupon: false,
      mcafee: mcafee,
      customer_type: "new",
      is_loading: false,
      orderForm: new this.$Form({
        academic_level: "",
        subject_area: "",
        language_style: "",
        urgency: "",
        document_type: "",
        spacing: "",
        writer_level: "",
        style: 1,
        additional_services: [],
        pages: 1,
        no_sources: 0,
        charts: 0,
        slides: 0,
        title: "",
        description: "",
        state: "",
        speciality: "",
        files: [],
        writer_id: "",
        coupon_code: "",
      }),
      registerForm: new this.$Form({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        country: "",
        password: "",
      }),
      loginForm: new this.$Form({
        email: "",
        password: "",
      }),
    };
  },
  async mounted() {
    this.$store.state.loading = true;
    await this._getSetups();
    await this._getCoupon();
    if (this.isAuthenticated && Object.keys(this.authUser).length > 0) {
      await this.getWriters();
    }
    await this.getCountries();
    this.$store.state.loading = false;
  },
  methods: {
    ...mapActions("setups", ["getSetups", "getCountries"]),
    ...mapActions("auth", ["doLogin", "doRegister"]),
    ...mapActions("order", ["_calculatePrice", "_placeOrder", "_getCoupon"]),
    ...mapActions("writers", ["getWriters"]),
    _getSetups() {
      this.getSetups()
        .then(() => {
          this.orderForm.subject_area = this.defaults.def_subject_area;
          this.orderForm.urgency = this.defaults.def_urgency;
          this.orderForm.document_type = this.defaults.def_doc_type;
          this.orderForm.academic_level = this.defaults.def_academic_level;
          this.orderForm.language_style = this.language_styles[0].id;
          this.orderForm.spacing = this.defaults.def_spacing;
          this.orderForm.writer_level = this.defaults.def_writer_level;
          this.orderForm.style = this.defaults.def_style;
          this.calculatePrice();
        })
        .catch((err) => {
          console.log("getsetups", err);
          this.$router.push({ name: "503" });
        });
    },

    activateAcademicLevel(level) {
      this.orderForm.academic_level = level.id;
      this.calculatePrice();
    },
    activateLanguageStyle(style) {
      this.orderForm.language_style = style.id;
    },
    activateSpacing(space) {
      this.orderForm.spacing = space.id;
      this.calculatePrice();
    },
    activateWriterPref(pref) {
      this.orderForm.writer_level = pref.id;
      this.calculatePrice();
    },
    updatePages(option) {
      if (!option) {
        if (this.orderForm.pages > 0) {
          this.orderForm.pages--;
        }
      } else {
        if (this.orderForm.pages <= this.number_of_pages.max_pages) {
          this.orderForm.pages++;
        }
      }
      this.calculatePrice();
    },
    decrementSources() {
      if (this.orderForm.no_sources > 0) {
        this.orderForm.no_sources--;
      }
    },
    updateCharts(option) {
      if (!option) {
        if (this.orderForm.charts > 0) {
          this.orderForm.charts--;
        }
      } else {
        this.orderForm.charts++;
      }
      this.calculatePrice();
    },
    updateSlides(option) {
      if (!option) {
        if (this.orderForm.slides > 0) {
          this.orderForm.slides--;
        }
      } else {
        this.orderForm.slides++;
      }
      this.calculatePrice();
    },

    calculatePrice() {
      console.log("this.orderForm", this.orderForm);
      this.is_loading = true;
      this._calculatePrice(this.orderForm)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },

    uploadFiles(event) {
      console.log("eventfiles ", event.target.files);
      this.orderForm.files = event.target.files;
    },

    async doPlaceOrder(action) {
      console.log("action", action);
      console.log("customer_type", this.customer_type);

      // if is logged in place order
      this.is_loading = true;
      if (this.isAuthenticated && Object.keys(this.authUser).length > 0) {
        // place order
        this._placeOrder(this.orderForm)
          .then((res) => {
            if (res.status === 200) {
              this.$router.push(`/orders/home/order/${res.data.Data.order_id}`);
              // alert('Success')
              this.loading = false;
            } else {
              

              this.$swal({
              icon: 'error',
              title: 'Error',
              text: res.data
                  ? res.data.Message
                  : "Error while placing order, please try again",
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
          })
            }
          })
          .catch((err) => {
            console.log(err);
            this.is_loading = false;
           

            this.$swal({
              icon: 'error',
              title: 'Error',
              text: err.response.data ? err.response.data.Message : "Error",
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
          })
          })
          .finally(() => (this.loading = false));
      } else {
        if (this.customer_type === "new") {
          // register new customer
          this.loginForm.email = this.registerForm.email;
          this.loginForm.password = this.registerForm.password;
          await this.doRegister(this.registerForm)
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              this.is_loading = false;
              console.log(err);
             

              this.$swal({
              icon: 'error',
              title: 'Error',
              text: err.response.data ? err.response.data.Message : "Error",
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
          })
            });
        }
        // login customer

        await this.doLogin(this.loginForm)
          .then((res) => {
            console.log("login res", res);
            localStorage.setItem(
              "token",
              `Bearer ${res.data.Data.access_token}`
            );
          })
          .catch((err) => {
            console.log("loggin err", err);
            this.is_loading = false;
           

            this.$swal({
              icon: 'error',
              title: 'Error',
              text:  err.response.data ? err.response.data.Message : "Error",
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
          })
          });

        axios.defaults.headers.common = {
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: localStorage.getItem("token"),
        };

        // place order
        console.log("token", localStorage.getItem("token"));
        await this._placeOrder(this.orderForm)
          .then((res) => {
            document.location.href = `/orders/home/order/${res.data.Data.order_id}`;
            // this.$router.push(`/orders/home/order/${res.data.Data.order_id}`);
          })
          .catch((err) => {
            this.is_loading = false;
           

            this.$swal({
              icon: 'error',
              title: 'Error',
              text: err.response.data ? err.response.data.Message : "Error",
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
          })

            console.log("order err", err);
          });

        this.is_loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.is-card {
  position: sticky;
  top: 20px;
}

@media screen and (max-width: 768px) {
  label {
    text-align: left !important;
  }
}

.order-topic.text-left {
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  // min-height: 4.5em !important;
  max-height: 4.5em !important;
}

.btn-group {
  overflow-x: scroll;
  width: 100%;
}
</style>